var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "content" }, [
    _c("ul", { staticClass: "header" }, [
      _c("li", { attrs: { title: "名称" } }, [_vm._v("名称")]),
      _c("li", { attrs: { title: "基数" } }, [_vm._v("基数")]),
      _c("li", { attrs: { title: "险种" } }, [_vm._v("险种")]),
      _c("li", { attrs: { title: "比例" } }, [_vm._v("比例")]),
      _c("li", { attrs: { title: "额外固定金额" } }, [_vm._v("额外固定金额")]),
      _c("li", { attrs: { title: "本月缴纳金额" } }, [_vm._v("本月缴纳金额")]),
      _c(
        "li",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFlag,
              expression: "showFlag",
            },
          ],
          attrs: { title: "补缴" },
        },
        [_vm._v("补缴")]
      ),
    ]),
    _c("ul", { staticClass: "table-body" }, [
      _c("li", [_vm._v(_vm._s(_vm.detailForm.insureClassifyName))]),
      _c("li", [
        _vm._v(_vm._s(_vm._f("stateFormatNum")(_vm.detailForm.paymentBase))),
      ]),
      _c(
        "li",
        _vm._l(_vm.detailForm.insureTypeList, function (item, index) {
          return _c(
            "div",
            {
              key: index,
              staticClass: "table-item item",
              style:
                "height:" + 100 / _vm.detailForm.insureTypeList.length + "%",
            },
            [_vm._v(" " + _vm._s(item.insureName) + " ")]
          )
        }),
        0
      ),
      _c(
        "li",
        _vm._l(_vm.detailForm.insureTypeList, function (item, index) {
          return _c("div", { key: index }, [
            _c("div", { staticClass: "table-item" }, [
              _vm._v("公司 " + _vm._s(item.companyScale || "-")),
            ]),
            _c("div", { staticClass: "table-item" }, [
              _vm._v("个人 " + _vm._s(item.personScale || "-")),
            ]),
          ])
        }),
        0
      ),
      _c(
        "li",
        _vm._l(_vm.detailForm.insureTypeList, function (item, index) {
          return _c("div", { key: index }, [
            _c("div", { staticClass: "table-item" }, [
              _vm._v(
                _vm._s(_vm._f("stateFormatNum")(item.companyExtra || "-"))
              ),
            ]),
            _c("div", { staticClass: "table-item" }, [
              _vm._v(_vm._s(_vm._f("stateFormatNum")(item.personExtra || "-"))),
            ]),
          ])
        }),
        0
      ),
      _c(
        "li",
        _vm._l(_vm.detailForm.insureTypeList, function (item, index) {
          return _c("div", { key: index }, [
            _c("div", { staticClass: "table-item" }, [
              _vm._v(_vm._s(_vm._f("stateFormatNum")(item.companyPay || "-"))),
            ]),
            _c("div", { staticClass: "table-item" }, [
              _vm._v(_vm._s(_vm._f("stateFormatNum")(item.personPay || "-"))),
            ]),
          ])
        }),
        0
      ),
      _c(
        "li",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.showFlag,
              expression: "showFlag",
            },
          ],
        },
        _vm._l(_vm.detailForm.insureTypeList, function (item, index) {
          return _c("div", { key: index }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.editFlag,
                    expression: "!editFlag",
                  },
                ],
                staticClass: "table-item",
              },
              [
                _vm._v(
                  _vm._s(_vm._f("stateFormatNum")(item.companySupply || "-"))
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.editFlag,
                    expression: "editFlag",
                  },
                ],
                staticClass: "table-item",
              },
              [
                _c("CbNumber", {
                  attrs: {
                    min: -9999,
                    "decimal-separator": true,
                    precision: 2,
                    "negative-number": true,
                    placeholder: "",
                  },
                  on: { change: _vm.updateCompanySupply },
                  model: {
                    value: item.companySupply,
                    callback: function ($$v) {
                      _vm.$set(item, "companySupply", $$v)
                    },
                    expression: "item.companySupply",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.editFlag,
                    expression: "!editFlag",
                  },
                ],
                staticClass: "table-item",
              },
              [
                _vm._v(
                  _vm._s(_vm._f("stateFormatNum")(item.personSupply || "-"))
                ),
              ]
            ),
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.editFlag,
                    expression: "editFlag",
                  },
                ],
                staticClass: "table-item",
              },
              [
                _c("CbNumber", {
                  attrs: {
                    min: -9999,
                    "decimal-separator": true,
                    precision: 2,
                    "negative-number": true,
                    placeholder: "",
                  },
                  on: { change: _vm.updatePersonSupply },
                  model: {
                    value: item.personSupply,
                    callback: function ($$v) {
                      _vm.$set(item, "personSupply", $$v)
                    },
                    expression: "item.personSupply",
                  },
                }),
              ],
              1
            ),
          ])
        }),
        0
      ),
    ]),
    _c("div", { staticClass: "table-footer" }, [
      _c("div", { staticClass: "tableFooter" }, [
        _c("p", [
          _vm._v(
            "社保本月公司合计：￥" +
              _vm._s(
                _vm._f("stateFormatNum")(
                  Number(_vm.detailForm.companyTotal).toFixed(2) || "-"
                )
              ) +
              "元"
          ),
        ]),
        _c("p", [
          _vm._v(
            "社保本月个人合计：￥" +
              _vm._s(
                _vm._f("stateFormatNum")(
                  Number(_vm.detailForm.personTotal).toFixed(2) || "-"
                )
              ) +
              "元"
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }